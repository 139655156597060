<template>
	<van-popup v-model="visible" round position="bottom" closeable :style="{ height: '80%' }">
		<div class="place-container">
			<p class="title">{{ title }}</p>
			<div class="content-map"></div>
		</div>
	</van-popup>
</template>

<script>
export default {
	name: 'placePopup',
	components: {},
	data() {
		return {
			title: '位置',
			visible: false
		};
	},
	created() {},
	mounted() {},
	methods: {
		show(info) {
			this.visible = true;
		},
		hide() {
			this.visible = false;
		}
	}
};
</script>

<style lang="scss" scoped>
.place-container {
	width: 100%;
	height: 100%;
	padding: 10px;
	position: relative;
	padding-top: 46px;
	> .title {
		position: absolute;
		width: 100%;
		text-align: center;
		font-size: 16px;
		font-weight: 700;
		color: #666;
		line-height: 22px;
		left: 0;
		right: 0;
		top: 14px;
	}
	.content-map {
		width: 100%;
		height: 100%;
	}
}
</style>
