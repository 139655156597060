<template>
	<div class="sendOrders">
		<div class="sendOrders-container">
			<van-form label-width="60px">
				<van-field v-model="formData.taskType" label="任务类型" placeholder="请输入任务类型" readonly />
				<van-field v-model="formData.patientName" label="患者" placeholder="请选择患者" />
				<van-field v-model="formData.patientNo" label="患者编号" placeholder="请输入患者编号" />
				<van-field v-model="formData.bedNo" label="床号" placeholder="请输入床号" />
				<van-field v-model="formData.room" label="房间" placeholder="请输入房间" />
				<van-field v-model="formData.address1" label="位置" placeholder="请选择位置">
					<template #button>
						<button type="button" class="place-btn" @click="showPlace"><van-icon name="location" size="24" color="#999" /></button>
					</template>
				</van-field>
				<van-field v-model="formData.address" label="目的地" placeholder="请选择目的地">
					<template #button>
						<button type="button" class="place-btn" @click="showPlace"><van-icon name="location" size="24" color="#999" /></button>
					</template>
				</van-field>
			</van-form>
			<div class="handle-btn">
				<button type="button" class="reset-btn">重置</button>
				<button type="button" class="submit-btn" @click="submit">派单</button>
			</div>
		</div>
		<placePopup ref="placePopup"></placePopup>
	</div>
</template>

<script>
import placePopup from '../../components/placePopup/index.vue';
export default {
	name: 'sendOrders',
	components: { placePopup },
	data() {
		return {
			formData: {
				taskType: '患者运输',
				patientId: '',
				patientName: '',
				patientNo: '',
				bedNo: '',
				room: '',
				username: '',
				// 目的地
				address: '',
				x: '',
				z: '',
				zoneInfoId: '',
				mapId: '',
				// 位置
				address1: '',
				x1: '',
				z1: '',
				zoneInfoId1: '',
				mapId1: ''
			}
		};
	},
	created() {},
	mounted() {},
	methods: {
		selectPatient() {},
		showPlace() {
			this.$refs.placePopup.show();
		},
		submit() {
			this.$dialog
				.confirm({
					message: '确定提交？'
				})
				.then(res => {
					this.$toast.success('操作成功');
				})
				.catch(() => {
					this.$toast.fail('操作失败');
				});
		}
	}
};
</script>

<style lang="scss" scoped>
.sendOrders {
	height: 100%;
	background: #f5f7f9;
	padding: 10px;
	.sendOrders-container {
		height: 100%;
		overflow-y: auto;
		background: #fff;
		padding: 10px;
		border-radius: 8px;
		::v-deep.van-field__button {
			line-height: inherit;
		}
		.place-btn {
			display: flex;
			align-items: center;
			background: none;
		}
		.handle-btn {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-top: 32px;
			button {
				line-height: 32px;
				width: 40%;
				text-align: center;
				border-radius: 32px;
				font-size: 14px;
			}
			.reset-btn {
				border: 1px solid #ccc;
				color: #333;
				background: none;
			}
			.submit-btn {
				margin-left: 20px;
				background: #3e73fb;
				color: #fff;
			}
		}
	}
}
</style>
